<template>
  <div class="new-secret">
    <div v-if="saveHash">
      <div v-if="saveHash === 'E'">
        <p>Operation failed, could not save secret!</p>
      </div>
      <div v-else>
        <p>Operation successful!</p>
        <p>Secret hash: {{ saveHash }}</p>
      </div>
      <button @click="reset">Back</button>
    </div>
    <form v-else @submit.prevent="save" ref="form">
      <text-field
        v-model="secretText"
        label="Secret text"
      />
      <text-field
        v-model="expAfterViews"
        label="Maximum views"
        type="number"
        min="1"
      />
      <text-field
        v-model="expAfterMins"
        label="Expire in  minutes"
        type="number"
        min="0"
      />
      <button type="submit">Save</button>
    </form>
  </div>
</template>

<script>
import api from '@/services/api'
import TextField from '@/components/TextField'

export default {
  name: 'NewSecret',
  components: { TextField },
  data: () => ({
    secretText: '',
    expAfterViews: 1,
    expAfterMins: 0,
    saveHash: ''
  }),
  methods: {
    reset () {
      this.secretText = ''
      this.expAfterViews = 1
      this.expAfterMins = 0
      this.saveHash = ''
    },
    async save () {
      if (this.secretText &&
          this.expAfterViews > 0 &&
          this.expAfterMins >= 0) {
        // setup form data
        const formData = new FormData()
        formData.append('secret', this.secretText)
        formData.append('expireAfterViews', this.expAfterViews)
        formData.append('expireAfter', this.expAfterMins)

        try {
          const res = await api.newSecret(formData)
          if (res.status === 200) {
            this.saveHash = res.data.hash
          }
          console.log(res)
        } catch (e) {
          this.saveHash = 'E'
        }
        // clear form
        this.$refs.form.reset()
      }
    }
  }
}
</script>

<style>
.new-secret p {
  margin: 20px auto;
}
</style>
